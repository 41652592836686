import React from 'react'
import LoadTranskey from '../../helpers/LoadTranskey'

export default function NextStepBtn(props) {

    let selectedKey = null

    switch(props.selectedStep) {
        case "date": selectedKey = "next"
        break
        case "event": selectedKey = null
        break
        case "reservation": selectedKey = "nextAndCheck"
        break
        case "pay": selectedKey = "submit"
        break
        default: 
    }

    const onSubmit = () => {
        props.onSubmit(props.selectedStep)
    }

    if(selectedKey == null) return null

    return(
        <div 
            className="sunbedres-next-step-btn btn"
            onClick={onSubmit}
        >
            {LoadTranskey(selectedKey)}
        </div>
    )
}