import React from 'react'
import LoadTranskey from '../../helpers/LoadTranskey'

export default function SubHeader(props) {

    let headerTextKey = null

    switch(props.selectedStep) {
        case "date": headerTextKey = "whichDay"
        break
        case "event": headerTextKey = "ourEvents"
        break
        case "reservation": headerTextKey = "reserve"
        break
        case "pay": headerTextKey = "payment"
        break
        default:
    }

    return  <div className="sunbedres-subheader select-none text-center text-xl lg:text-3xl mb-1 lg:mb-3 text-blue">{LoadTranskey(headerTextKey)}</div>
}