import React, { useState, useEffect, useContext } from "react"

import { CommContext } from "../../contexts/commContext"

import { withRouter } from "react-router-dom"
import { FadeLoader } from "react-spinners"
import SimplePayBackref from "../../components/SimplePayBackref"
import { toast } from "react-toastify"
import moment from "moment"

import LoadTranskey from "../../helpers/LoadTranskey"
import GetBookingByUUID from "../../helpers/GetBookingByUUID"

function BookingView(props) {
  const { ioClient, ioConnected } = useContext(CommContext)

  const [params, setParams] = useState(null)
  const [bookingData, setBookingData] = useState(null)
  const [bookingID, setBookingID] = useState(null)
  const [paymentSuccess, setPaymentSuccess] = useState(null)

  let dictionary = null

  try {
    dictionary = JSON.parse(localStorage["costes-res-dictionary"])
  } catch (e) {
    console.log(e)
  }

  useEffect(() => {
    setOrderParams()
    // eslint-disable-next-line
  }, [props.match.params.source])

  useEffect(() => {
    if (ioClient != null && ioConnected) {
      ioClient.on("bookingUpdated", onBookingUpdated)
    }

    return () => {
      if (ioClient != null) ioClient.off("bookingUpdated", onBookingUpdated)
    }
    // eslint-disable-next-line
  }, [ioClient, ioConnected])

  useEffect(() => {
    async function getBooking() {
      try {
        let resp = await GetBookingByUUID(bookingID)

        if (resp.success) {
          console.log(resp.data)
          setBookingData(resp.data)
        } else {
          setBookingData("notAvailable")
        }
      } catch (e) {
        toast.error(LoadTranskey("error"))
        console.log(e)
        setBookingData("notAvailable")
      }
    }

    if (bookingID != null) getBooking()
    // eslint-disable-next-line
  }, [bookingID])

  const onBookingUpdated = (bookingData) => {
    console.log(bookingData)
  }

  const setOrderParams = () => {
    let url = decodeURIComponent(props.location.search.substr(1))
    let params = {}

    for (let param of url.split("&")) {
      let aaa = param.split("=")
      params[aaa[0]] = aaa[1]
    }

    if (props.match.params.source === "simplePay") {
      localStorage.removeItem("costes-res-booking-uuid")
      try {
        let stringToDecode = params.r
        let decoded = JSON.parse(
          Buffer.from(stringToDecode, "base64").toString()
        )
        setParams(decoded)
        let splitRef = decoded.o.split("-")
        setBookingID(splitRef[2])
      } catch (err) {
        console.log(err)
      }
    }

    if (props.match.params.source === "viva") {
      localStorage.removeItem("costes-res-booking-uuid")
      try {
        let transactionId = params.t
        //let code = params.s
        setBookingID(params.orderRef)
        setParams(params)
        if (params.eventId === "0" && transactionId != null) {
          setPaymentSuccess(true)
        } else setPaymentSuccess(false)
      } catch (e) {
        console.log("error")
        setPaymentSuccess(false)
      }
    }

    if (props.match.params.source === "lastbooking") {
      setParams(true)
      setBookingID(localStorage["costes-res-last-booking-uuid"])
    }

    if (props.match.params.source === "local") {
      setParams(true)
      setBookingID(params.bid)
    }
  }

  const startPayment = () => {
    localStorage["costes-res-booking-uuid"] = bookingData.id

    props.history.replace("/startbooking")
  }

  if (
    props.match.params.source === "lastorder" &&
    localStorage["costes-res-last-booking-uuid"] == null
  )
    return (
      <div className="">
        <div className="">{LoadTranskey("noLastBooking")}</div>
        <div
          className="btn"
          onClick={() => {
            props.history.replace("/")
          }}
        >
          Start
        </div>
      </div>
    )

  if (params == null || bookingData == null) {
    console.log("Params, bookingData", params, bookingData)
    return (
      <div className="invis appear">
        <div className="">
          <FadeLoader color={"#fff"} loading={true} />
        </div>
      </div>
    )
  }

  if (bookingData === "notAvailable")
    return (
      <div className="absolute flex flex-col justify-center items-center centered-box lg:top-2/4 lg:left-2/4 w-full">
        <div className="bg-white text-blue m-2 w-full lg:w-1/3 text-center">
          This booking is not available.
        </div>
      </div>
    )

  return (
    <div className="absolute flex flex-col justify-center items-center centered-box lg:top-2/4 lg:left-2/4 w-full">
      {props.match.params.source === "simplePay" && (
        <div className="bg-white text-blue m-2 w-full lg:w-1/3">
          <SimplePayBackref
            statusCode={params.e}
            simplePayTransactionID={params.t}
            dictionary={dictionary}
            selectedLanguage={localStorage["selectedLanguage"]}
          />
          {params.e === "FAIL" && (
            <div className="btn mb-2" onClick={startPayment}>
              {LoadTranskey("tryAgain")}
            </div>
          )}
        </div>
      )}
      {props.match.params.source === "viva" && (
        <div className="bg-white text-blue m-2 w-full lg:w-1/3">
          <SimplePayBackref
            statusCode={paymentSuccess ? "SUCCESS" : "FAIL"}
            simplePayTransactionID={params.t}
            dictionary={dictionary}
            selectedLanguage={localStorage["selectedLanguage"]}
          />
          {paymentSuccess === false && (
            <div className="btn mb-2" onClick={startPayment}>
              {LoadTranskey("tryAgain")}
            </div>
          )}
        </div>
      )}
      {props.match.params.source === "local" &&
        bookingData.bookDeleted == null &&
        parseInt(bookingData.totalPrice) > parseInt(bookingData.totalPaid) && (
          <div className="p-3 m-2 z-60 bg-white text-blue w-full lg:w-1/3">
            <div className="text-center">{LoadTranskey("needToPay")}</div>
            <div className="btn mb-2" onClick={startPayment}>
              {LoadTranskey("pay")}
            </div>
          </div>
        )}
      {props.match.params.source === "local" &&
        bookingData.bookDeleted != null && (
          <div className="p-3 m-2 z-60 bg-white text-blue w-full lg:w-1/3 text-center">
            {LoadTranskey("deletedBooking")}
          </div>
        )}
      <div className="w-full lg:w-1/3 booking-view-container text-center text-xl mb-2 flex flex-col p-3 z-60 bg-white text-blue">
        {`${LoadTranskey("bookingId")}: ${bookingData.id}`}
      </div>
      <div className="w-full lg:w-1/3 booking-view-container flex flex-col p-3 z-60 bg-white text-blue">
        <div className="flex justify-center">
          <div className="text-grey w-1/2 pr-1 text-right">
            {LoadTranskey("bookName") + ":"}
          </div>
          <div className="pl-1 w-1/2 text-left">{bookingData.bookName}</div>
        </div>
        <div className="flex justify-center">
          <div className="text-grey w-1/2 pr-1 text-right">
            {LoadTranskey("date") + ":"}
          </div>
          <div className="pl-1 w-1/2 text-left">
            {moment(bookingData.bookDate).format("YYYY-MM-DD")}
          </div>
        </div>
        <div className="flex justify-center">
          <div className="text-grey w-1/2 pr-1 text-right">
            {LoadTranskey("reservation") + ":"}
          </div>
          <div className="pl-1 w-1/2 text-left">
            {bookingData.entities.map((ent, index) => (
              <div key={index} className="">
                {`${ent.seats} ${LoadTranskey("piece")} ${
                  ent.entityName[localStorage["selectedLanguage"]]
                }`}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="btn"
        onClick={() => {
          props.history.replace("/")
        }}
      >
        {LoadTranskey("backToHome")}
      </div>
    </div>
  )
}

export default withRouter(BookingView)
