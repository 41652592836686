import React, { useState, useEffect } from 'react'
import LoadTranskey from '../../helpers/LoadTranskey'

export default function EmailField(props) {

    const [email, setEmail] = useState(localStorage['costes-res-email'] != null ? localStorage['costes-res-email'] : "")

    useEffect(() => {
        if(localStorage['costes-res-email'] != null) setEmail(localStorage['costes-res-email'])
    // eslint-disable-next-line
    },[localStorage['costes-res-email']])


    const handleEmailChange = (e) => {
        localStorage['costes-res-email'] = e.target.value
        setEmail(e.target.value)
    }

    const handleBlur = (e) => {
        props.checkEmailAddress(e.target.value)
    }

    return(
        <div className="email-field-container lg:max-w-sm lg:mx-auto">
            {props.emailFieldMsgVisible && <div className="email-field-message leading-tight text-center text-xs lg:text-base text-white my-2">{LoadTranskey('emailFieldMsg')}</div>}
            <input type="email" className="form-input block rounded px-1 py-1 mx-auto mt-2" value={email} onChange={handleEmailChange} onBlur={handleBlur} disabled={props.disabled ? "disabled" : ""}/>
            {props.emailInvalid && <div className="email-error text-red text-xs text-center mt-1">{LoadTranskey('emailError')}</div>}
        </div>
    )
}