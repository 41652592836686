import React from 'react'
import { useHistory } from 'react-router-dom'

function ErrorFallback({error, resetErrorBoundary}) {


    const history = useHistory()

    let title = localStorage['selectedLanguage'] === 'hu' ? "Hiba történt" : "Error"
    let message = localStorage['selectedLanguage'] === 'hu' ? "Valami itt nem stimmel..." : "Something went wrong..."
    let tryAgain = localStorage['selectedLanguage'] === 'hu' ? "Újra próbálom" : "Try again"
    let back = localStorage['selectedLanguage'] === 'hu' ? "Vissza" : "Back"
    let reset = localStorage['selectedLanguage'] === 'hu' ? "App újraindítása" : "Reset app"

    console.error(error)

    const resetApp = () => {
        history.push('/')
        window.location.reload()
    }

    return(
        <div className="modal modal-error">
            <div className="modal-title">{title}</div>
            <div className="modal-message">{message}</div>
            <div className="modal-actions">
                <div className="modal-actions-action try-again" onClick={resetErrorBoundary}>{tryAgain}</div>
                <div className="modal-actions-action back" onClick={() => { history.goBack() }}>{back}</div>
                <div className="modal-actions-action reset-app" onClick={resetApp}>{reset}</div>
            </div>
        </div>
    )
}

export { ErrorFallback }
