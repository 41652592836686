import React, { useState, useContext, useEffect } from 'react'

import { CommContext } from '../../contexts/commContext'

import moment from 'moment'
import { toast } from 'react-toastify'

import LoadTranskey from '../../helpers/LoadTranskey'
import LoadAssets from '../../helpers/LoadAssets'
import GetEvents from '../../helpers/GetEvents'

import EventField from './EventField'

export default function EventBookingSelectEvent(props) {
    
    const { ioClient, ioConnected } = useContext(CommContext)

    const [eventBookingData, setEventBookingData] = useState({ count: 0, totalPrice: 0, bookDate: "1999-01-01T00:00", entities: { }})
    const [event, setEvent] = useState(props.selectedEvent)

    useEffect(() => {
        if(localStorage['costes-res-selected-event-booking-data'] != null) {
            try {
                setEventBookingData(JSON.parse(localStorage['costes-res-selected-event-booking-data']))
            } catch(e) {
                console.log(e)
            }
        }
        // eslint-disable-next-line
    }, [localStorage['costes-res-selected-event-booking-data']])

    useEffect(() => {
        setEvent(props.selectedEvent)
    }, [props.selectedEvent])

    useEffect(() => {

        if(ioClient != null && ioConnected) { ioClient.on('entityAvailabilityUpdated', onEntityAvailabilityUpdated) }

        return () => { if(ioClient != null) ioClient.off('entityAvailabilityUpdated', onEntityAvailabilityUpdated) }
    // eslint-disable-next-line
    }, [ioClient, ioConnected])  

    const onEntityAvailabilityUpdated = async (data) => {

        console.log("update-selected-event", data)

        let params = {
            "fromDate": moment().format("YYYY-MM-DD"),
            "toDate": moment().add(1, 'y').format("YYYY-MM-DD")
        }

        try {
    
            let todayEvents = await GetEvents(params)

            for(let todayEvent of todayEvents) {
                
                if(todayEvent.id === event.entity.id)  setEvent(todayEvent)
            }
        } catch(e) {
            toast.error(LoadTranskey('error'))
        }
       
    }


    if(event == null) return <div className="">{LoadTranskey('noEventChoosed')}</div>

    console.log(event)

    return(
        <>
            <div className="selected-event-container flex flex-grow flex-col lg:flex-row overflow:auto lg:overflow-hidden">
                <div className="selected-event-image-container w-full md:w-1/3">
                    <LoadAssets 
                        imgSrc={event.entity.imagePrimary[localStorage['selectedLanguage']]}
                        imgSrcXs={event.entity.imagePrimary[localStorage['selectedLanguage']]}
                        addClass="object-cover w-full"
                    />
                </div>
                <div className="w-full md:w-2/3 overflow-auto">
                    <div className="event-date text-base pl-2">{moment(event.entity.dateAffected).format("YYYY-MM-DD") + " " +event.entity.firstOpenSegment.hour + ":" + event.entity.firstOpenSegment.minute}</div>
                    <EventField 
                        event={event}
                        eventBookingData={eventBookingData}
                        setEventBookingData={setEventBookingData}
                    />
                    {event.entity.children.length > 0 && event.entity.children
                    .filter(event => event.entity.enabled === 1)
                    .map(event =>
                        <EventField 
                            key={event.id}
                            event={event}
                            eventBookingData={eventBookingData}
                            setEventBookingData={setEventBookingData}
                        />
                    )}
                </div>
            </div>
        </>
    )
}