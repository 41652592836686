import React, { useState, useContext, useEffect } from 'react'
import PinInput from 'react-pin-input'

import MainContext from '../../contexts/MainContext'
import { LoginContext } from '../../contexts/loginContext'

import LoadTranskey from '../../helpers/LoadTranskey'

import { generatePin, validatePin } from '../Login'

import EmailField from '../EmailField'
import {ReactComponent as Close} from "../../svg/close.svg"

export default function PinModal(props) {

    const { setPinModalVisible } = useContext(MainContext)
    const { setLoggedIn, setUser } = useContext(LoginContext)

    const [ref, setRef] = useState(null)
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [emailTyped, setEmailTyped] = useState(localStorage['costes-res-email'] != null)
    const [countDownTimer, setCountDownTimer] = useState(20)

    const keyFunction = (event) => {
		if(event.keyCode === 27) {
			setPinModalVisible(false)
		}
        if(event.keyCode === 13) {
            if(!props.visible) return
            if(checkEmailAddress(localStorage['costes-res-email']) && localStorage['counting'] == null) handleSubmitEmail()			
		}
	}

	useEffect(() => {
		document.addEventListener("keydown", keyFunction, false)

		return function cleanup() {
			document.removeEventListener("keydown", keyFunction, false)
		}
	// eslint-disable-next-line
	}, [])

    useEffect(() => {
        const timer = setTimeout(() => {
            if(countDownTimer === 20) {
                localStorage.removeItem('counting')
                return
            }
            if(countDownTimer > 0) {
                setCountDownTimer(countDownTimer-1)
                if(localStorage['counting'] == null) localStorage['counting'] = true
            } 
            if(countDownTimer === 1) {
                setCountDownTimer(20)
            }
          }, 1000);
          return () => clearTimeout(timer);
          // eslint-disable-next-line 
    }, [countDownTimer])

    useEffect(() => {
		if(localStorage['costes-res-email'] != null) setEmailTyped(true)
	// eslint-disable-next-line
	}, [localStorage['costes-res-email']])

    const handleSubmitPin = async (pinCode) => {

        try {

            let response = await validatePin(localStorage['costes-res-email'], pinCode)

            if(response.success) {

                setLoggedIn(true)
                setUser(response.user)
                setPinModalVisible(false)
            } else ref.clear()

        } catch(e) {
            console.log(e)
            setPinModalVisible(false)
        }
    }

    const handleSubmitEmail = () => {

        if(emailInvalid || countDownTimer < 20) return null

        setCountDownTimer(19)

        generatePin(localStorage['costes-res-email'])

        setEmailTyped(true)
    }

    const checkEmailAddress = (email) => {

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let valid = re.test(String(email).toLowerCase())

        setEmailInvalid(!valid)
        return valid
    }

    return(
        <div className={`pin-modal absolute top-0 w-screen h-full z-50 p-4 bg-blue bg-opacity-90 ${!props.visible ? 'hidden' : ''} flex flex-col justify-center items-center`}>
            <div className="absolute top-1 right-1 h-5 w-5 cursor-pointer" onClick={() => { setPinModalVisible(false) }}><Close className="fill-current text-white" /></div>
            {emailTyped && <div className="invis appear flex flex-col justify-center items-center">
                <div className="pin-modal-message text-center text-white mb-4">{LoadTranskey('pinModalMsg')}</div>
                <PinInput 
                    length={4} 
                    initialValue="" 
                    type="numeric" 
                    inputMode="number"
                    onComplete={handleSubmitPin}
                    ref={(n) => setRef(n)}
                    focus={true}
                />
                <div className="pin-modal-message2 text-center text-white my-4 text-xs font-gotham-light leading-tight">{LoadTranskey('pinModalMsg2')}</div>
                <EmailField 
                    checkEmailAddress={checkEmailAddress} 
                    emailInvalid={emailInvalid} 
                    disabled={countDownTimer < 20}
                />
                <div className={`btn ${countDownTimer < 20 ? 'opacity-70 cursor-not-allowed' : ''}`} onClick={handleSubmitEmail} >{LoadTranskey('send')+(countDownTimer < 20 ? " ("+countDownTimer+")" : "")}</div>
            </div>}
            {!emailTyped && <div className="invis appear">
                <EmailField 
                    checkEmailAddress={checkEmailAddress} 
                    emailInvalid={emailInvalid} 
                    emailFieldMsgVisible={true} 
                    disabled={countDownTimer < 20} 
                />
                <div className={`btn ${countDownTimer < 20 ? 'opacity-70 cursor-not-allowed' : ''}`} onClick={handleSubmitEmail} >{LoadTranskey('send')+(countDownTimer < 20 ? " ("+countDownTimer+")" : "")}</div>
            </div>}
        </div>
    )
}