import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Welcome() {
    
    return(
        <div className="welcome-container flex flex-col h-screen z-10">
           <div className="z-10 h-1/2"><NavLink to="/sunbedres"><div className="sunbed-res font-2xl flex h-full justify-center items-center bg-blue text-white">Nyugágy</div></NavLink></div>
           <div className="z-10 h-1/2"><NavLink to="/eventbooking"><div className="event-res font-2xl flex h-full justify-center items-center bg-blue-light text-white">Esemény</div></NavLink></div>
        </div>
    )
}