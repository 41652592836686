import React from 'react'
import LoadTranskey from '../../helpers/LoadTranskey'

export default function EventBookingStepSelect(props) {

    const handleClick = (step) => {
    
        if(step === "reservation" && !props.stepsDone.event) return
        if(step === "pay" && (!props.stepsDone.event || !props.stepsDone.reservation)) return
        props.onSelectStep(step)
    }

    return(
        <div className="event-booking-step-select-container flex text-xs cursor-pointer lg:text-base">
            <div 
                className={`step-event relative flex justify-center items-center w-1/3 p-1 select-none ${props.selectedStep === 'event' ? 'selected bg-white' : props.stepsDone.event ? 'bg-blue text-white' : 'bg-grey text-grey-light'}`} 
                onClick={() => { handleClick('event') }}
            >
                <div className="number absolute left-1">1</div>
                <div className="label">{LoadTranskey('event')}</div>
            </div>
            <div 
                className={`step-reservation relative flex justify-center items-center w-1/3 p-1 select-none ${props.selectedStep === 'reservation' ? 'selected bg-white' : props.stepsDone.reservation ? 'bg-blue text-white' : 'bg-grey text-grey-light'}`} 
                onClick={() => { handleClick('reservation') }}
            >
                <div className="number absolute left-1">2</div>
                <div className="label">{LoadTranskey('reservation')}</div>
            </div>
            <div 
                className={`step-pay relative flex justify-center items-center w-1/3 p-1 select-none ${props.selectedStep === 'pay' ? 'selected bg-white' : props.stepsDone.pay ? 'bg-blue text-white' : 'bg-grey text-grey-light'}`} 
                onClick={() => { handleClick('pay') }}
            >
                <div className="number absolute left-1">3</div>
                <div className="label">{LoadTranskey('pay')}</div>
            </div>
        </div>
    )
}