import React, { useState, useEffect } from 'react'

const LoadAssets = React.memo(({imgSrc, imgSrcXs, addClass = "", onClick = null, defaultImg = `${process.env.PUBLIC_URL}/assets/img/default_img.png` }) => {
	
	const imgPath = imgSrc != null ? imgSrc : defaultImg
	const placeholder = imgSrcXs != null ? imgSrcXs : defaultImg
	
	const [currentSrc, updateSrc] = useState(placeholder)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		// start loading original image
		const imageToLoad = new Image()
		imageToLoad.src = imgPath
		imageToLoad.onerror = (e) => {
			updateSrc(defaultImg)
			setLoading(false)
		}
		imageToLoad.onload = () => {
			// When image is loaded replace the src and set loading to false
			setLoading(false)
			updateSrc(imgPath)
		}
	// eslint-disable-next-line
	}, [imgPath])

	return (
		<img
			src={currentSrc}
			className={addClass}
			style={{
				opacity: loading ? 0.5 : 1,
				transition: "opacity .15s linear, height 0.3s ease",
				filter: loading ? "blur(2px)" : "none"
			}}
			alt={"img"}
			onClick={onClick}
		/>
	)

})
  
export default LoadAssets