import axios from 'axios'
import config from '../../config'

export default async function SimplePayStart(bookingID, languageCode) {

    let resp = false

    try {

        let data = {
            bookingID,
            languageCode,
            backRef: config.backRef
        }
  
        let response = await axios(config.endpoint+'/api/v1/payment/simplePay/start', {      
          method: 'post',
          data: JSON.stringify(data)
        })
        
        resp = response.data.data
       
    } catch (e) {
        console.log(e)
    }

    return resp
}