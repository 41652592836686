import { useState, createContext, useMemo } from 'react'

const CommContext = createContext(); 

const CommProvider = (props) => {

    const [ioConnected, setIOConnected] = useState(false);
    const [ioClient, setIOClient] = useState(null);

    const value = useMemo(() => ({ ioConnected, setIOConnected }), [ioConnected])
    const value2 = useMemo(() => ({ ioClient, setIOClient }), [ioClient])

    return (
        <CommContext.Provider
            value={{...value, ...value2}}
        >
            {props.children}
        </CommContext.Provider>
    )
}

export { CommContext, CommProvider }