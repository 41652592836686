import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import 'react-calendar/dist/Calendar.css'
import './index.css'
import './style.scss'
import config from './config'

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename={config.basename}>
            <App />
        </BrowserRouter>
    </React.StrictMode>, document.getElementById('root')
)
