// - Order/OrderWhere.js

import React, { useEffect, useState } from "react"
import { FadeLoader } from "react-spinners"
import Success from "./Success"
import Failed from "./Failed"

function SimplePayBackref(props) {
  const [transactionID, setTransactionID] = useState(null)
  const [success, setSuccess] = useState(null)
  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(null)

  useEffect(() => {
    setOrderData()
    // eslint-disable-next-line
  }, [])

  const setOrderData = () => {
    let statusCode = props.statusCode
    let simplePayTransactionID = props.simplePayTransactionID

    setTransactionID(simplePayTransactionID)
    setStatus(statusCode)
    setLoading(false)
    statusCode === "SUCCESS" ? setSuccess(true) : setSuccess(false)
  }

  return (
    <div className="simplePayBackrefContainer">
      {loading && (
        <div className="loadingContainer">
          <FadeLoader color={"grey"} loading={true} />
        </div>
      )}
      {!loading && success && (
        <Success
          dictionary={props.dictionary}
          selectedLanguage={props.selectedLanguage}
          transactionID={transactionID}
        />
      )}
      {!loading && !success && (
        <Failed
          dictionary={props.dictionary}
          selectedLanguage={props.selectedLanguage}
          transactionID={transactionID}
          status={status}
        />
      )}
    </div>
  )
}

export default SimplePayBackref
