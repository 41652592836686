import axios from 'axios'
import config from '../../config'

export default async function GetLayout(layoutID, date) {

    let res = []
    
    try {
  
        let response = await axios(config.endpoint+'/api/v1/availability/layout', {      
          method: 'get',
          params: { layoutID, date }
        })
        
        return response.data

    } catch (e) {
        console.log(e)
    }

    return res
}