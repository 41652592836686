import React, { useState, useEffect, useContext } from 'react'

import { LoginContext } from '../../contexts/loginContext'

import Header from '../../components/Header'
import SubHeader from '../../components/SubHeader'
import NextStepBtn from '../../components/NextStepBtn'
import Payment from '../../components/Payment'

import SunbedResStepSelect from '../../components/SunbedResStepSelect'
import SunbedResCalendar from '../../components/SunbedResCalendar'
import SunbedResSelectItems from '../../components/SunbedResSelectItems'

import moment from 'moment'
import cloneDeep from 'lodash.clonedeep'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import LoadTranskey from '../../helpers/LoadTranskey'

function SunbedRes(props) {

    const { loggedIn } = useContext(LoginContext)

    const [selectedStep, setSelectedStep] = useState(localStorage['costes-res-selected-sunbed-step'] != null ? localStorage['costes-res-selected-sunbed-step'] : "date")
    const [stepsDone, setStepsDone] = useState({ date: false, reservation: false, pay: false })

    useEffect(() => {
        if(localStorage['costes-res-sunbed-selected-date'] == null) localStorage['costes-res-sunbed-selected-date'] = moment().format("YYYY-MM-DD")
    }, [])

    useEffect(() => {

        if(localStorage['costes-res-sunbed-selected-date'] == null) onSelectStep("date")

        if(localStorage['costes-res-sunbed-selected-date'] != null) {
            let newValue = cloneDeep(stepsDone)
            newValue.date = true
            setStepsDone(newValue)
        }
    // eslint-disable-next-line
    },[localStorage['costes-res-sunbed-selected-date']])

    const onSelectStep = (step) => {

        localStorage['costes-res-selected-sunbed-step'] = step
        setSelectedStep(step)
    }

    const onSelectDate = (date) => {

        localStorage['costes-res-reserve-items'] = JSON.stringify({ entitiesAllData: {}, totalPrice: 0})
        localStorage['costes-res-sunbed-selected-date'] = date
        
        let newValue = cloneDeep(stepsDone)
        newValue.reservation = false
        setStepsDone(newValue)
    }

    const goToNextStep = (step) => {

        switch(step) {
            case "date": onSelectStep("reservation")
            break
            case "reservation": onSelectStep("pay")
            break
            case "pay": props.history.replace("/startbooking")
            break
            default:
        }
    }

    const onSubmitStep = (step) => {

        console.log(step)

        let bookingData = null

        try {

            bookingData = {...JSON.parse(localStorage['costes-res-reserve-items'])}
    
          } catch(e) {
            console.log(e)
        }

        if(step === "date") {
           
            let newValue = cloneDeep(stepsDone)
            newValue.date = true
            setStepsDone(newValue)
            goToNextStep(step)
        }

        if(step === "reservation" && loggedIn) {

            if(bookingData?.bookDate != null && moment(bookingData?.bookDate).isAfter(moment().endOf('day'))) {    
            
                let totalSelected = 0
                try {
                    let selectedItems = JSON.parse(localStorage['costes-res-reserve-items']).entities
                    totalSelected = Object.keys(selectedItems).reduce((sum, key) => sum + parseFloat(selectedItems[key].seats || 0), 0)
                    console.log(totalSelected)
                } catch(e) {
                    console.log(e)
                }

                if(totalSelected > 0) {
                    let newValue = cloneDeep(stepsDone)
                    newValue.reservation = true
                    setStepsDone(newValue)
                    goToNextStep(step)
                } else toast.error(LoadTranskey('notChoosed')+"!")
            } else {
                toast.error(LoadTranskey('dateError'))
            }
        }

        if(step === "pay" && loggedIn) {
            if(bookingData?.bookDate != null && moment(bookingData?.bookDate).isAfter(moment().endOf('day'))) {  
                let totalSelected = 0
                try {
                    let selectedItems = JSON.parse(localStorage['costes-res-reserve-items']).entities
                    totalSelected = Object.keys(selectedItems).reduce((sum, key) => sum + parseFloat(selectedItems[key].seats || 0), 0)
                } catch(e) {
                    console.log(e)
                }

                if(totalSelected > 0) {

                    if(localStorage['costes-res-user-data'] == null) {
                        toast.error(LoadTranskey('missingData'))
                        return null
                    }

                    try {
                        let userData = JSON.parse(localStorage['costes-res-user-data'])
                        
                        if(!userData.policy) {
                            toast.error(LoadTranskey('policyCheckError'))
                            return null
                        }

                        
                        let missingData = []

                        if(userData.invoiceNeeded) {
                            missingData = Object.entries(userData).filter(data => {
                                return (data[0] !== "firstName" && data[0] !== "lastName" && data[1] === "")
                            })
                        } else {
                            missingData = Object.entries(userData).filter(data => {
                                return (data[0] !== "tax" && data[0] !== "companyName" && data[1] === "")
                            })
                        }
                        
                        if(missingData.length > 0) {
                            for(let data of missingData) {
                                toast.error(LoadTranskey('missingData')+": "+LoadTranskey(data[0]))
                            }
                        } else {
                            localStorage['costes-res-sunbed-start-payment'] = true
                            localStorage.removeItem('costes-res-last-booking-id')
                            localStorage.removeItem('costes-res-booking-uuid')
                            let newValue = cloneDeep(stepsDone)
                            newValue.pay = true
                            setStepsDone(newValue)
                            setTimeout(() => { goToNextStep(step) }, 300)                        
                        }
                    } catch(e) {
                        console.log(e)
                    }
                } else toast.error(LoadTranskey('notChoosed')+"!")
            } else toast.error(LoadTranskey('dateError'))
        }
    }

    return(
        <div className="sunbedres-container overflow-y-auto w-full h-full main-container flex flex-col z-10">
            <Header headerTitleKey={'sunbedReserve'} img={'sunbed.png'} />
            <div className="sunbedres-body w-full flex flex-col max-w-screen-lg mx-auto z-10 bg-white bg-opacity-80">
                <SunbedResStepSelect 
                    selectedStep={selectedStep} 
                    onSelectStep={onSelectStep}
                    stepsDone={stepsDone}
                />
                <div className="sunbedres-content flex-grow  flex flex-col justify-between p-2 lg:px-44 lg:pb-4">
                    <SubHeader selectedStep={selectedStep} />
                    
                    {selectedStep === "date" && <SunbedResCalendar onSelectDate={onSelectDate} />}

                    {selectedStep === "reservation" && <SunbedResSelectItems date={localStorage['costes-res-sunbed-selected-date']} />}

                    {selectedStep === "pay" && <Payment date={localStorage['costes-res-sunbed-selected-date']} reservation={localStorage['costes-res-reserve-items']} />}

                    <NextStepBtn selectedStep={selectedStep} onSubmit={onSubmitStep} />
                </div>
            </div>
        </div>
    )
}

export default withRouter(SunbedRes)