import axios from 'axios'
import config from '../../config'

export default async function GetBooking(bookingUUID) {

    let resp = null

    try {
  
        let response = await axios.get(config.endpoint+'/api/v1/booking/uuid/'+bookingUUID, {})
        
        resp = response.data
       
    } catch (e) {
        console.log(e)
    }

    return resp
}