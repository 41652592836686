import axios from 'axios'
import config from '../../config'

export default async function GetEvents(params) {

    let res = []
    
    try {
  
        let response = await axios(config.endpoint+'/api/v1/availability/events', {      
          method: 'get',
          params
        })
        
        if(response.data.success) {
            res = response.data.data
            //setEvents(eventsJSON)
        }
    } catch (e) {
        console.log(e)
    }

    return res
}