import React from 'react'

export default function Failed(props) {

  const transactionID = props.transactionID != null ? props.transactionID : 'No transaction id!'
  const status = props.status != null ? props.status : 'No status!'
  const dictionary = props.dictionary
  const selectedLanguage = props.selectedLanguage != null ? props.selectedLanguage : 'hu'

  if(dictionary == null) {
    console.log("SimplePay failed: missing dictionary!")
    return null
  }

  const renderStatus = () => {
    switch(status) {
      case "FAIL": return(<p>{dictionary['app_simple_failedPayment'].translation[selectedLanguage]}</p>)
      //break
      case "FAILED": return(<p>{dictionary['app_simple_failedPayment'].translation[selectedLanguage]}</p>)
      //break
      case "CANCEL": return(<p>{dictionary['app_simple_cancelPayment'].translation[selectedLanguage]}</p>)
      //break
      case "CANCELLED": return(<p>{dictionary['app_simple_cancelPayment'].translation[selectedLanguage]}</p>)
      //break
      case "TIMEOUT": return(<p>{dictionary['app_simple_timeOut'].translation[selectedLanguage]}</p>)
      //break
      case "NOT_FOUND": return(<p>{dictionary['app_simple_notFound'].translation[selectedLanguage]}</p>)
      //break
      case "CARD_NOTAUTHORIZED": return(<p>{dictionary['app_simple_failedPayment'].translation[selectedLanguage]}</p>)
      //break
      default: return(<p>{dictionary['app_simple_otherError'].translation[selectedLanguage]}: {status}</p>)
      //break
    }
  }    
  return (
    <div className="failedPaymentContainer">
      <h3>{dictionary['app_simple_failedTransaction'].translation[selectedLanguage]}</h3>
      <p>{`SimplePay ${dictionary['app_simple_transActionId'].translation[selectedLanguage]}: ${transactionID}`}</p>
      {renderStatus()}
      <p>{dictionary['app_simple_tryAgain'].translation[selectedLanguage]}</p>
    </div>
  )
}
  