import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify'

import LoadAssets from '../../helpers/LoadAssets'
import LoadTranskey from '../../helpers/LoadTranskey'

export default function EventTile(props) {

    const [eventAvailable, setEventAvailable] = useState(null)

    useEffect(() => {

        setEventAvailable(isEventAvailable())
        // eslint-disable-next-line
    }, [props.data])

    const handleSelectEvent = () => {

        if(!eventAvailable) {
            toast.error(LoadTranskey('eventNotAvailable')+"!")
            return null
        }

        if(typeof props.onSelectEvent === 'function') props.onSelectEvent(props.data)
    }

    const isEventAvailable = () => {

        let availableSeats = 0
        let isOpen = false

        try {
            if(props.data.availability != null) {
                let availability = Object.values(props.data.availability)[0]
                availableSeats = availability.segments['00'].availableSeats
                isOpen = props.data.entity.firstOpenSegment != null
            }
        } catch(e) {
            console.log(e)
        }

        return (availableSeats > 0 && isOpen)

    }

    return(
        <div className="event-tile-container relative w-1/2 lg:w-1/3 p-1 cursor-pointer filter transition-all duration-400 lg:brightness-90 lg:hover:brightness-100" onClick={handleSelectEvent}>
            {!eventAvailable && <div className="absolute top-0 left-0 bg-grey opacity-60 m-1" style={{ width: "calc(100% - 0.5rem)", height: "calc(100% - 0.5rem)"}}></div>}
            <LoadAssets 
                imgSrc={props.data.entity.imagePrimary[localStorage['selectedLanguage']]}
                imgSrcXs={props.data.entity.imagePrimary[localStorage['selectedLanguage']]}
                addClass={"w-full h-full object-cover"}
            />
            <div className="absolute bottom-1 text-white"></div>
        </div>
    )
}