export default function LoadTranskey(transkey = null) {

    let dictionary = null

    try {
       dictionary = JSON.parse(localStorage['costes-res-dictionary'])
    } catch(e) {
        console.log(e)
    }

    if(dictionary == null || transkey == null) {
        if (process.env.NODE_ENV === 'development') {
            console.log("Load transkey failed. Dictionary: "+dictionary+", transkey: "+transkey)
        }
        return null
    }

    if(dictionary == null) return null

    let response = null

    try {
        response = dictionary[transkey].translation[localStorage['selectedLanguage']]
    } catch(e) {
        console.log(e)
        console.log("transkey:", transkey)
    }

    return(response)
}