import React, { useState, useEffect } from 'react'
import MainContext from './contexts/MainContext'
import { LoginProvider } from './contexts/loginContext'
import { CommProvider } from './contexts/commContext'

import dictionary from './dictionary.json'
import config from './config'

import ClearCache from 'react-clear-cache'
import { Route, Switch, withRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from './helpers/ErrorHandlers'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import { FadeLoader } from 'react-spinners'
import LogRocket from 'logrocket'
import moment from 'moment'

import LoadAssets from './helpers/LoadAssets'

import SocketHandler from './components/SocketHandler'
import { Login } from './components/Login'
import PinModal from './components/PinModal'

import Welcome from './screens/Welcome'
import SunbedRes from './screens/SunbedRes'
import StartBooking from './screens/StartBooking'
import EventBooking from './screens/EventBooking'
import BookingView from './screens/BookingView'

function App() {

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage['selectedLanguage'] == null ? 'hu' : localStorage['selectedLanguage'])
  const [pinModalVisible, setPinModalVisible] = useState(false)
  const [appLoaded, setLoaded] = useState(false)
  
  axios.interceptors.request.use(config => {
    config.headers['Accept'] = 'application/json'
    config.headers['Content-Type'] = 'application/json'
    config.headers['Authorization'] = 'Bearer '+localStorage['costes-res-api-token']
    return config
  })

  useEffect(() => {

    localStorage['costes-res-dictionary'] = JSON.stringify(dictionary)

    if(localStorage['selectedLanguage'] == null) localStorage['selectedLanguage'] = 'hu'

    LogRocket.init('cnp98d/costes-booking')

    setTimeout(() => {
      setLoaded(true)
    }, 300)

    //TÖRÖLNI KELL MAJD!!!
    try {
      if(localStorage['costes-res-reserve-items'] != null) {
        let data = JSON.parse(localStorage['costes-res-reserve-items'])
        if(data.baldachin != null) localStorage.removeItem('costes-res-reserve-items')
      }
    } catch(e) {
      console.log(e)
    }

    try {

      if(localStorage['costes-res-sunbed-selected-date'] != null) {

        let sunbedSelectedDateInPast = moment(localStorage['costes-res-sunbed-selected-date']).isBefore(moment(), 'day')
        if(sunbedSelectedDateInPast) localStorage['costes-res-sunbed-selected-date'] = moment().format("YYYY-MM-DD")
      }

    } catch(e) {
      console.log(e)
    }
    // eslint-disable-next-line
  }, [])

  const changeLanguage = (language) => {
    localStorage['selectedLanguage'] = language
    setSelectedLanguage(language)
  }

  let mainContextValue = {
    selectedLanguage,
    changeLanguage,
    setPinModalVisible 
  }

  if(!appLoaded) return (
    <div className="w-screen h-screen flex items-center justify-center">
      <FadeLoader color={'#05a8cc'} />
    </div>
  )

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} >
      <LoginProvider>
        <CommProvider>
          <MainContext.Provider value={mainContextValue}>
            <Login />
            <SocketHandler />
            <ClearCache basePath={config.basename}>
              {({ isLatestVersion, emptyCacheStorage }) => {
                if(!isLatestVersion) {
                  emptyCacheStorage()
                  window.location.reload()
                }
                return null
              }}              
            </ClearCache>
            <ToastContainer toastClassName="custom-toast" autoClose={1500} />
            <div className="invis appear main-container font-gotham w-screen h-screen relative" >
              {!config.prod && <div className="absolute top-0 left-0 w-full h-6 z-50 text-center" style={{ background: '#ffff00' }}>Development build!!</div>}
              <div className="w-full h-full absolute top-0 left-0 z-0">
                <LoadAssets 
                  imgSrc={`${process.env.PUBLIC_URL}/assets/img/background.webp`}
                  imgSrcXs={`${process.env.PUBLIC_URL}/assets/img/background_xs.jpg`}
                  addClass="object-cover w-full h-full"
                />
              </div>
              <div className="absolute bottom-1 right-1 opacity-70 z-50" style={{fontSize: "10px"}}>v{config.version}</div>
              <Switch>
                <Route path={"/"} exact >
                  <ErrorBoundary FallbackComponent={ErrorFallback} >
                    <Welcome />
                  </ErrorBoundary>
                </Route>
                <Route path={"/sunbedres"} >
                  <ErrorBoundary FallbackComponent={ErrorFallback} >
                    <SunbedRes />
                  </ErrorBoundary>
                </Route>
                <Route path={"/eventbooking"} >
                  <ErrorBoundary FallbackComponent={ErrorFallback} >
                    <EventBooking />
                  </ErrorBoundary>
                </Route>
                <Route path={"/startbooking"} >
                  <ErrorBoundary FallbackComponent={ErrorFallback} >
                    <StartBooking />
                  </ErrorBoundary>
                </Route>
                <Route path={"/bookingview/:source"} >
                  <ErrorBoundary FallbackComponent={ErrorFallback} >
                    <BookingView />
                  </ErrorBoundary>
                </Route>
              </Switch>
              <PinModal visible={pinModalVisible} />
            </div>
          </MainContext.Provider>
        </CommProvider>
      </LoginProvider>
    </ErrorBoundary>
  )
}

export default withRouter(App)