import React, { useState, useEffect, useContext } from 'react'

import { LoginContext } from '../../contexts/loginContext'

import cloneDeep from 'lodash.clonedeep'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import LoadTranskey from '../../helpers/LoadTranskey'

import Header from '../../components/Header'
import SubHeader from '../../components/SubHeader'
import NextStepBtn from '../../components/NextStepBtn'
import Payment from '../../components/Payment'

import EventBookingStepSelect from '../../components/EventBookingStepSelect'
import EventBookingCalendar from '../../components/EventBookingCalendar'
import EventBookingSelectEvent from '../../components/EventBookingSelectEvent'

function EventBooking(props) {

    const { loggedIn } = useContext(LoginContext)

    const [selectedStep, setSelectedStep] = useState(localStorage['costes-res-selected-event-booking-step'] != null ? localStorage['costes-res-selected-event-booking-step'] : "event")
    const [stepsDone, setStepsDone] = useState({ event: false, reservation: false, pay: false })
    const [selectedEvent, setSelectedEvent] = useState(null)

    useEffect(() => {

        if(localStorage['costes-res-selected-event-booking-step'] == null) onSelectStep("event")

        if(localStorage['costes-res-selected-event-booking-step'] != null) {
            if(selectedEvent != null) {
                let newValue = cloneDeep(stepsDone)
                newValue.event = true
                setStepsDone(newValue)
            } else onSelectStep("event")
        }
    // eslint-disable-next-line
    },[localStorage['costes-res-selected-event-booking-step']])

    const onSelectStep = (step) => {
        
        if(step === "event") {
            setSelectedEvent(null)
            let newValue = cloneDeep(stepsDone)
            newValue.reservation = false
            setStepsDone(newValue)
        }
        localStorage['costes-res-selected-event-booking-step'] = step
        setSelectedStep(step)
    }

    const onSelectEvent = (data) => {
        
        setSelectedEvent(data)
        localStorage['costes-res-selected-event-date'] = moment(data.entity.dateAffected).format("YYYY-MM-DD")
        localStorage.removeItem('costes-res-selected-event-booking-data')

        let newValue = cloneDeep(stepsDone)
        newValue.reservation = false
        newValue.event = true
        setStepsDone(newValue)
        goToNextStep('event')
    }

    const goToNextStep = (step) => {

        switch(step) {
            case "event": onSelectStep("reservation")
            break
            case "reservation": onSelectStep("pay")
            break
            case "pay": props.history.replace("/startbooking")
            break
            default:
        }
    }

    const onSubmitStep = (step) => {

        if(step === "reservation" && loggedIn) {
            
            let totalSelected = 0
            
            try {
                totalSelected = JSON.parse(localStorage['costes-res-selected-event-booking-data']).count
            } catch(e) {
                console.log(e)
            }

            if(totalSelected > 0) {
                let newValue = cloneDeep(stepsDone)
                newValue.reservation = true
                setStepsDone(newValue)
                goToNextStep(step)
            } else toast.error(LoadTranskey('notChoosed')+"!")
        }

        if(step === "pay" && loggedIn) {
            
            let totalSelected = 0

            try {
                totalSelected = JSON.parse(localStorage['costes-res-selected-event-booking-data']).count
            } catch(e) {
                console.log(e)
            }

            if(totalSelected > 0) {

                if(localStorage['costes-res-user-data'] == null) {
                    toast.error(LoadTranskey('missingData'))
                    return null
                }

                try {
                    let userData = JSON.parse(localStorage['costes-res-user-data'])
                    if(!userData.policy) {
                        toast.error(LoadTranskey('policyCheckError'))
                        return null
                    }

                    let missingData
                    
                    if(userData?.invoiceNeeded) {               
                        missingData = Object.entries(userData).filter(data => {
                            return ((data[0] !== "firstName" && data[0] !== "lastName") && data[1] === "")
                        })
                    } else {
                        missingData = Object.entries(userData).filter(data => {
                            return (data[0] !== "tax" && data[0] !== "companyName" && data[1] === "")
                        })
                    }

                    if(missingData.length > 0) {
                        for(let data of missingData) {
                            toast.error(LoadTranskey('missingData')+": "+LoadTranskey(data[0]))
                        }
                    } else {
                        if(userData.phone.indexOf("_") > 0) {
                            toast.error(LoadTranskey('wrongPhoneFormat'))
                        } else {
                            localStorage['costes-res-event-booking-start-payment'] = true
                            localStorage.removeItem('costes-res-last-booking-id')
                            localStorage.removeItem('costes-res-booking-uuid')
                            let newValue = cloneDeep(stepsDone)
                            newValue.pay = true
                            setStepsDone(newValue)
                            setTimeout(() => { goToNextStep(step) }, 300) 
                        }
                    }
                } catch(e) {
                    console.log(e)
                }
            } else toast.error(LoadTranskey('notChoosed')+"!")
        }
        
    }

    return(
        <div className="event-booking-container overflow-y-auto w-full h-full main-container flex flex-col z-10">
            <Header headerTitleKey={'eventBooking'} img={'event.png'} />
            <div className="event-booking-body w-full flex flex-col mx-auto z-10 max-w-screen-lg">
                <EventBookingStepSelect 
                    selectedStep={selectedStep} 
                    onSelectStep={onSelectStep}
                    stepsDone={stepsDone}
                />
                <div className="event-booking-content flex-grow bg-white bg-opacity-80 flex flex-col justify-between p-2 lg:pb-4 select-none">
                    <SubHeader selectedStep={selectedStep} />
                    
                    {(selectedStep === "event" || selectedEvent == null) && <EventBookingCalendar onSelectEvent={onSelectEvent} />}

                    {selectedStep === "reservation" && selectedEvent != null && <EventBookingSelectEvent selectedEvent={selectedEvent} />}

                    {selectedStep === "pay" && <Payment date={localStorage['costes-res-selected-event-date']} reservation={localStorage['costes-res-selected-event-booking-data']} />}

                    <NextStepBtn selectedStep={selectedStep} onSubmit={onSubmitStep} />
                </div>
            </div>
        </div>
    )
}

export default withRouter(EventBooking)