import axios from 'axios'
import config from '../../config'

export default async function SetBooking(params) {

    let resp = false

    try {

        let data = { booking : { ...params } }
  
        let response = await axios(config.endpoint+'/api/v1/booking', {      
          method: 'post',
          data: JSON.stringify(data)
        })
        
        resp = response.data.data.bookingID
       
    } catch (e) {
        console.log(e)
    }

    return resp
}