import React from 'react'

export default function Success(props) {

    const dictionary = props.dictionary
    const selectedLanguage = props.selectedLanguage != null ? props.selectedLanguage : 'hu'
    const transactionID = props.transactionID != null ? props.transactionID : 'No transaction ID!'

    if(dictionary == null) {
        console.log("Simple success component: missing dictionary")
        return null
    }

    return (
        <div className="successContainer">
            <div className="message">
                <h3>{dictionary['simplePay_transactionSuccess'].translation[selectedLanguage]}</h3>
                <p>{`${dictionary['simplePay_transactionID'].translation[selectedLanguage]}: ${transactionID}`}</p>
                <p>{dictionary['simplePay_message'].translation[selectedLanguage]}</p>
            </div>
        </div>
    )
}
  