import { useEffect, useContext } from 'react'

import { LoginContext } from '../../contexts/loginContext'
import { CommContext } from '../../contexts/commContext'

import config from '../../config'
import socketIOClient from 'socket.io-client'

export default function SocketHandler(props) {

  const { loggedIn } = useContext(LoginContext)
  const { setIOConnected, setIOClient, ioClient } = useContext(CommContext)

  useEffect(() => {
    
    const disconnectIO = () => {
      if (ioClient != null && ioClient.connected) {
        ioClient.disconnect()
      }
    }

    const connectIO = () => {

      if (ioClient != null) {
        ioClient.connect()
        return
      }

      let ioclient = socketIOClient(config.apiBaseUrl, {
        path: '/socket.io',
        transports: ['websocket', 'polling'],
      })

      ioclient.on('connect', () => {
        ioclient.emit('authentication', {
          token: localStorage['costes-res-api-token'],
        })
        setIOConnected(true)
      })

      ioclient.on('disconnect', () => {
        setIOConnected(false)
      })

      setIOClient(ioclient)
    }

    if (!loggedIn) disconnectIO()
    else connectIO()

    // eslint-disable-next-line
  }, [loggedIn, setIOConnected, setIOClient, ioClient])

  return loggedIn
}
